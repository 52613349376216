@import 'checkbox';
@import 'dialog';
@import 'stepper';
@import 'form-field';
@import 'headers';
@import 'indicators';
@import 'table';
@import 'tabs';

*,
::before,
::after {
  box-sizing: border-box;
}

textarea.mat-input-element {
  box-sizing: content-box;
}
