@use '@angular/material' as mat;
@mixin mat-tab-style($theme) {
  $accent: map-get($theme, accent);

  .mat-tab-group.mat-primary .mat-ink-bar,
  .mat-tab-nav-bar.mat-primary .mat-ink-bar {
    background-color: mat.get-color-from-palette($accent);
    height: 4px;
  }

  .mat-tab-nav-bar,
  .mat-tab-header {
    margin-right: auto;
    align-self: flex-start;
    border-bottom: 1px solid map_get($apd-grey, 900);

    .mat-tab-label,
    .mat-tab-link {
      color: map_get($apd-grey, 900);
      opacity: 1;

      &.mat-tab-disabled {
        color: map_get($apd-grey, 400);
      }
    }
  }

  .mat-tab-nav-bar,
  .mat-tab-header {
    .mat-tab-link,
    .mat-tab-label {
      font-weight: 400;
      font-size: 16px;
    }
  }

  .mat-tab-group.mat-primary .mat-tab-label:focus,
  .mat-tab-group.mat-primary .mat-tab-link:focus,
  .mat-tab-nav-bar.mat-primary .mat-tab-label:focus,
  .mat-tab-nav-bar.mat-primary .mat-tab-link:focus {
    background-color: transparent;
  }

  body .mat-tab-group.mat-primary .mat-tab-label.cdk-focused.cdk-keyboard-focused:not(.mat-tab-disabled),
  body .mat-tab-group.mat-primary .mat-tab-link.cdk-focused.cdk-keyboard-focused:not(.mat-tab-disabled),
  body .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-focused.cdk-keyboard-focused:not(.mat-tab-disabled),
  body .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-focused.cdk-keyboard-focused:not(.mat-tab-disabled) {
    background-color: transparent;
    @include outline(-3px);
  }
}
