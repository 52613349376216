mat-form-field {
  /* remove extra height used for label when no label present; see https://github.com/angular/components/issues/11845#issuecomment-527399826 */
  &:not(.mat-form-field-has-label) .mat-form-field-infix {
    border-top-width: 0;
  }

  &.inline-validation {
    padding-bottom: 0;

    .mat-form-field-wrapper {
      align-items: center;
      display: flex;
      flex-direction: row;
      padding-bottom: 0;
    }

    .mat-form-field-flex {
      flex: 0 0 auto;
      width: 12rem;
    }

    .mat-form-field-underline {
      bottom: 0.25em;
      width: 12rem;
    }

    .mat-form-field-subscript-wrapper {
      margin: 0 0 0 0.5rem;
      position: static;
    }
  }

  &.no-underline {
    .mat-form-field-underline {
      visibility: hidden;
    }
  }

  &.no-validation {
    .mat-form-field-wrapper {
      padding-bottom: 0;

      .mat-form-field-infix {
        border-top-width: 0;
      }

      .mat-form-field-underline {
        bottom: 0.2em;
      }
    }
  }

  .mat-form-field-suffix > .mat-icon {
    font-size: 14px;
  }

  /* Give the placeholder text a disabled look if the input field is disabled */
  input:disabled ~ span > .mat-form-field-label {
    color: rgba(0, 0, 0, 0.38); /* as used in theme */
  }
}
