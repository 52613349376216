.mat-horizontal-stepper-header-container,
.mat-horizontal-content-container {
  background-color: #e7eaec;

  form.white-form {
    background-color: white;
    padding: 24px;
    margin-bottom: 20px;
  }
}

button[matsteppernext] {
  float: right;
}
