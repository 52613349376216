@mixin mat-button-style() {
  .mat-button:not(.mat-flat-button):not(.mat-raised-button):not(.mat-stroked-button) {
    text-transform: uppercase;
  }

  button.mat-raised-button,
  button.mat-button,
  a.mat-raised-button,
  a.mat-button,
  div.mat-button-background {
    padding: 0 15px;
    height: 40px;
    border-radius: 0;
    line-height: normal;
  }

  .mat-raised-button:not([class*='mat-elevation-z']),
  .mat-raised-button:not([disabled]):active:not([class*='mat-elevation-z']) {
    box-shadow: none;
  }

  .mat-raised-button:not([disabled]) {
    transition: border 100ms, padding 100ms;
    border: 4px solid white;
  }

  .mat-raised-button:not([disabled]):hover {
    padding: 0 19px;
    border: 0 solid white;
  }

  .mat-button.cdk-focused.cdk-keyboard-focused,
  .mat-raised-button.cdk-focused.cdk-keyboard-focused,
  .mat-icon-button.cdk-focused.cdk-focused.cdk-keyboard-focused,
  .mat-fab.cdk-focused.cdk-focused.cdk-keyboard-focused,
  .mat-mini-fab.cdk-focused.cdk-keyboard-focused {
    .mat-button-focus-overlay {
      opacity: 0;
    }
  }
}
