@use 'sass:map';
@use '@angular/material' as mat;
@import './palette';
@import './component-styles/_body';
@import './component-styles/_focus';
@import './component-styles/_mat-button';
@import './component-styles/_mat-button-toggle';
@import './component-styles/_mat-card';
@import './component-styles/_mat-checkbox';
@import './component-styles/_mat-chip-list';
@import './component-styles/_mat-datepicker';
@import './component-styles/_mat-dialog';
@import './component-styles/_mat-expansion';
@import './component-styles/_mat-form-field';
@import './component-styles/_mat-list';
@import './component-styles/_mat-menu';
@import './component-styles/_mat-paginator';
@import './component-styles/_mat-progress-bar';
@import './component-styles/_mat-radio';
@import './component-styles/_mat-select';
@import './component-styles/_mat-selection-list';
@import './component-styles/_mat-slide-toggle';
@import './component-styles/_mat-slider';
@import './component-styles/_mat-snackbar';
@import './component-styles/_mat-tab';
@import './component-styles/_mat-table';
@import './component-styles/_mat-toolbar';
@import './component-styles/_mat-tooltip';
@import './component-styles/_scrollbar';
@import './component-styles/_typography';

$apd-primary-palette: mat.define-palette($apd-dark-blue, 900, A200);
$apd-accent-palette: mat.define-palette($apd-dark-blue, 300);
$apd-warn-palette: mat.define-palette($apd-red, 700, 400);

$apd-primary-color: map-get($apd-primary-palette, default);
$apd-accent-color: map-get($apd-accent-palette, default);
$apd-warn-color: map-get($apd-warn-palette, default);

// Includes the common styles for Angular Material, in addition to APD overrides
// **Be sure that you only ever include this mixin once!**
@mixin loadTheme() {
  $app-light-theme: mat.define-light-theme($apd-primary-palette, $apd-accent-palette, $apd-warn-palette);
  $apd-light-theme: map-merge(
      $app-light-theme,
      (
        background: $apd-light-theme-background,
        foreground: $apd-light-theme-foreground
      )
  );

  @include mat.core($apd-typography);
  @include mat.all-component-themes($apd-light-theme);
  @include mat.all-component-typographies($apd-typography);

  .apd-dark-theme {
    $dark-theme-component-color: mat.define-palette($apd-white);
    $apd-dark-theme: mat.define-dark-theme(
        $dark-theme-component-color,
        $dark-theme-component-color,
        $apd-warn-palette
    );
    @include mat.all-component-themes($apd-dark-theme);
    color: white;

    .mat-form-field {
      .mat-form-field-underline {
        background-color: white;
      }
    }
  }

  // Custom styles for APD
  @include body-style();
  @include apd-default-keyboard-focus();
  @include mat-button-style();
  @include mat-button-toggle-style();
  @include mat-card-style($app-light-theme);
  @include mat-checkbox-style();
  @include mat-chip-list-style();
  @include mat-datepicker-style();
  @include mat-dialog-style($app-light-theme);
  @include mat-expansion-style();
  @include mat-form-field-style($app-light-theme);
  @include mat-list-style();
  @include mat-menu-style();
  @include mat-paginator-style();
  @include mat-progress-bar-style();
  @include mat-radio-style();
  @include mat-select-style();
  @include mat-selection-list-style();
  @include mat-slide-toggle-style();
  @include mat-slider-style();
  @include mat-snackbar-style();
  @include mat-tab-style($apd-light-theme);
  @include mat-table-style($app-light-theme);
  @include mat-toolbar-style();
  @include mat-tooltip-style();
  @include scrollbar-style($app-light-theme);
  @include typography();
}
