@mixin mat-expansion-style() {
  mat-expansion-panel.mat-expansion-panel {
    border-radius: 0px;

    &:not([class*='mat-elevation-z']) {
      box-shadow: none;
    }

    .mat-expansion-panel-header.cdk-focused.cdk-keyboard-focused:focus {
      @include outline(-3px);
      background-color: transparent;
    }
  }
}
