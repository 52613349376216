@use '@angular/material' as mat;
@mixin color($color, $opacity) {
  background-color: rgba(mat.get-color-from-palette($color, 900), $opacity);
}

@mixin scrollbar-style($theme) {
  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    border-radius: 5px;
  }

  ::-webkit-scrollbar-track {
    display: none;
  }

  ::-webkit-scrollbar-thumb {
    @include color($apd-grey, 0.3);
    border-radius: 10px;

    &:hover {
      @include color($apd-grey, 0.4);
    }

    &:active {
      @include color($apd-grey, 0.5);
    }
  }

  .apd-dark-theme {
    ::-webkit-scrollbar-thumb, &::-webkit-scrollbar-thumb {
      @include color($apd-white, 0.4);

      &:hover {
        @include color($apd-white, 0.5);
      }

      &:active {
        @include color($apd-white, 0.7);
      }
    }
  }
}
