@use '@angular/material' as mat;
@mixin mat-form-field-style($theme) {
  $accent: map-get($theme, accent);

  mat-form-field.mat-focused {
    .mat-form-field-underline, .mat-input-underline {
      .mat-form-field-ripple, .mat-input-ripple {
        height: 3px;
        background-color: mat.get-color-from-palette($accent);
      }
    }
  }

  .mat-form-field-ripple {
    background-color: mat.get-color-from-palette($accent);
  }
}
