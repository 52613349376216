@mixin mat-radio-style() {
  mat-radio-button.mat-radio-button {
    margin: 5px;

    .mat-radio-outer-circle,
    .mat-radio-inner-circle {
      width: 18px;
      height: 18px;
    }

    ~ .mat-radio-button {
      margin-left: 16px;
    }

    &.cdk-focused.cdk-keyboard-focused {
      @include outline(6px);

      .mat-radio-persistent-ripple {
        opacity: 0;
      }
    }
  }
}
