@use '@angular/material' as mat;
@mixin mat-card-style($theme) {
  $primary: map-get($theme, primary);
  $primary-color: mat.get-color-from-palette($primary);

  mat-card.mat-card {
    padding: 24px;
    border-radius: 0px;

    &:not([class*='mat-elevation-z']) {
      box-shadow: none;
    }

    .mat-card-header .mat-card-header-text {
      margin: 0;
      color: $primary-color;
      border-radius: 0;

      mat-card-title.mat-card-title {
        font-size: 20px;
        font-weight: 700;
        color: $primary-color;
      }
    }
  }
}
