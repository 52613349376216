@use '@angular/material' as mat;
mat-dialog-container {
  .grey-title {
    display: flex;
    padding: 10px 0;
    align-items: center;
    justify-content: center;
    background: mat.get-color-from-palette($apd-grey, 100);
    margin: -40px -40px 0 -40px;
    position: relative;

    h2[mat-dialog-title] {
      margin: 0;
      text-transform: uppercase;
    }

    .mat-icon-button {
      position: absolute;
      right: 0;
    }
  }

  .mat-dialog-content {
    margin: 0;
    max-height: 70vh;
    padding: 0;

    .description {
      font-weight: bold;
      color: var(--primary-color);
    }

    > app-spinner {
      display: flex;
      justify-content: center;
    }
  }

  .mat-dialog-actions {
    justify-content: flex-end;
    margin-top: 8px;

    button {
      margin-left: 16px;
      text-transform: uppercase;
    }
  }
}
