@mixin mat-menu-style() {
  div.mat-menu-panel {
    border-radius: 0px;
  }

  button.mat-menu-item.cdk-focused.cdk-keyboard-focused:not(.mat-disabled),
  button.mat-menu-item.cdk-focused.cdk-program-focused:not(.mat-disabled){
    @include outline(-3px);
    background: transparent;
  }
}
