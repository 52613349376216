@use "@angular/material" as mat;

$black-87-opacity: rgba(black, 0.87);
$black-12-opacity: rgba(black, 0.12);
$black-6-opacity: rgba(black, 0.06);
$white-87-opacity: rgba(white, 0.87);

$apd-dark-blue: (
  50: #00BCD0,
  100: #00ADC1,
  200: #009EB2,
  300: #008FA3,
  400: #008094,
  500: #007185,
  600: #006276,
  700: #005367,
  800: #004458,
  900: #003145,
  A100: #b8eaff,
  A200: #5BC6E8,
  A400: #0ca5e4,
  A700: #003145,
  contrast: (
    50: $black-87-opacity,
    100: $black-87-opacity,
    200: $black-87-opacity,
    300: white,
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: $black-87-opacity,
    A200: $black-87-opacity,
    A400: white,
    A700: white,
  )
);

$apd-orange: (
  50: #fedbb8,
  100: #ff8000,
  200: #ff8000,
  300: #ff8000,
  400: #ff8000,
  500: #ff8000,
  600: #ff8000,
  700: #ff8000,
  800: #ff8000,
  900: #ff8000,
  A100: #ff8000,
  A200: #ff8000,
  A400: #ff8000,
  A700: #ff8000,
  contrast: (
    50: $black-87-opacity,
    100: $black-87-opacity,
    200: $black-87-opacity,
    300: $black-87-opacity,
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: $black-87-opacity,
    A200: white,
    A400: white,
    A700: white,
  )
);

$apd-red: (
  50: #d72311,
  100: #d72311,
  200: #d72311,
  300: #d72311,
  400: #d72311,
  500: #d72311,
  600: #d72311,
  700: #d72311,
  800: #d72311,
  900: #d72311,
  A100: #d72311,
  A200: #d72311,
  A400: #d72311,
  A700: #d72311,
  contrast: (
    50: $black-87-opacity,
    100: $black-87-opacity,
    200: $black-87-opacity,
    300: $black-87-opacity,
    400: $black-87-opacity,
    500: white,
    600: white,
    700: white,
    800: $white-87-opacity,
    900: $white-87-opacity,
    A100: $black-87-opacity,
    A200: white,
    A400: white,
    A700: white,
  )
);

$apd-green: (
  50: #0d8762,
  100: #0d8762,
  200: #0d8762,
  300: #0d8762,
  400: #0d8762,
  500: #0d8762,
  600: #0d8762,
  700: #0d8762,
  800: #0d8762,
  900: #0d8762,
  A100: #0d8762,
  A200: #0d8762,
  A400: #0d8762,
  A700: #0d8762,
  contrast: (
    50: $black-87-opacity,
    100: $black-87-opacity,
    200: $black-87-opacity,
    300: $black-87-opacity,
    400: $black-87-opacity,
    500: white,
    600: white,
    700: white,
    800: $white-87-opacity,
    900: $white-87-opacity,
    A100: $black-87-opacity,
    A200: white,
    A400: white,
    A700: white,
  )
);

$apd-blue: (
  50: #194ae7,
  100: #194ae7,
  200: #194ae7,
  300: #194ae7,
  400: #194ae7,
  500: #194ae7,
  600: #194ae7,
  700: #194ae7,
  800: #194ae7,
  900: #194ae7,
  A100: #194ae7,
  A200: #194ae7,
  A400: #194ae7,
  A700: #194ae7,
  contrast: (
    50: $black-87-opacity,
    100: $black-87-opacity,
    200: $black-87-opacity,
    300: $black-87-opacity,
    400: $black-87-opacity,
    500: white,
    600: white,
    700: white,
    800: $white-87-opacity,
    900: $white-87-opacity,
    A100: $black-87-opacity,
    A200: white,
    A400: white,
    A700: white,
  )
);

$apd-grey: (
  50: #EEF3F6,
  100: #DADFE2,
  200: #C6CBCE,
  300: #B2B7BA,
  400: #9EA3A6,
  500: #8A8F92,
  600: #727679,
  700: #62676A,
  800: #4E5356,
  900: #333333,
  A100: #ffffff,
  A200: #eeeeee,
  A400: #bdbdbd,
  A700: #616161,
  contrast: (
    50: $black-87-opacity,
    100: $black-87-opacity,
    200: $black-87-opacity,
    300: $black-87-opacity,
    400: $black-87-opacity,
    500: $black-87-opacity,
    600: $white-87-opacity,
    700: $white-87-opacity,
    800: $white-87-opacity,
    900: $white-87-opacity,
    A100: $black-87-opacity,
    A200: $black-87-opacity,
    A400: $black-87-opacity,
    A700: $white-87-opacity,
  )
);

/**
* Only used for dark theme
**/
$apd-white: (
  50: #FFF,
  100: #FFF,
  200: #FFF,
  300: #FFF,
  400: #FFF,
  500: #FFF,
  600: #FFF,
  700: #FFF,
  800: #FFF,
  900: #FFF,
  A100: #FFF,
  A200: #FFF,
  A400: #FFF,
  A700: #FFF,
  contrast: (
    50: #FFF,
    100: #FFF,
    200: #FFF,
    300: #FFF,
    400: #FFF,
    500: #FFF,
    600: #FFF,
    700: #FFF,
    800: #FFF,
    900: #FFF,
    A100: #FFF,
    A200: #FFF,
    A400: #FFF,
    A700: #FFF
  )
);

// Background palette for light themes.
$apd-light-theme-background: (
  status-bar: map_get($apd-grey, 300),
  app-bar: map_get($apd-dark-blue, 900),
  background: #e7eaec,
  hover: rgba(black, 0.04), // TODO(kara): check style with Material Design UX
  card: white,
  dialog: white,
  disabled-button: map_get($apd-grey, 100),
  raised-button: map_get($apd-grey, 100),
  focused-button: $black-6-opacity,
  selected-button: map_get($apd-grey, 300),
  selected-disabled-button: map_get($apd-grey, 400),
  disabled-button-toggle: map_get($apd-grey, 200),
  unselected-chip: map_get($apd-grey, 300),
  disabled-list-option: map_get($apd-grey, 200),
  table-header: map-get($apd-dark-blue, 900),
  table-sum: #C3CACF,
  table-row-active: map-get($apd-grey, 300),
  table-row-selected: map-get($apd-grey, 300)
);

// Foreground palette for light themes.
$apd-light-theme-foreground: (
  base: black,
  divider: $black-12-opacity,
  dividers: $black-12-opacity,
  disabled: rgba(black, 0.38),
  disabled-button: rgba(black, 0.38),
  disabled-text: rgba(black, 0.38),
  hint-text: rgba(black, 0.38),
  secondary-text: map_get($apd-grey, 900),
  icon: rgba(black, 0.54),
  icons: rgba(black, 0.54),
  text: map_get($apd-grey, 900),
  slider-min: rgba(black, 0.87),
  slider-off: rgba(black, 0.26),
  slider-off-active: rgba(black, 0.38),
);
