@mixin body-style() {
  body {
    padding: 0;
    margin: 0;

    // Helps fonts on OSX looks more consistent with other systems
    // Isn't currently in button styles due to performance concerns
    * {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }

    main {
      padding: 20px 45px 40px 45px;
      box-sizing: border-box;
    }
  }
}
