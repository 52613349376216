/* 1)  fighting the framework, but look: no !important */
.section-header,
.mat-typography .section-header /* 1 */ {
  align-items: center;
  display: flex;
  justify-content: stretch;
  position: relative;

  > * {
    margin: 0;
    padding: 0 1.25em;
  }

  &::after,
  &::before {
    border-top: 1px solid currentColor;
    content: '';
    flex: 1 1 auto;
  }
}
