@mixin mat-chip-list-style() {
  .mat-chip-list.cdk-focused.cdk-keyboard-focused {
    outline: 0;
  }

  .mat-chip.mat-standard-chip:focus {
    @include outline();

    &::after {
      opacity: 0;
    }
  }
}
