@use '@angular/material' as mat;
@mixin mat-dialog-style($theme) {
  $primary: map-get($theme, primary);

  mat-dialog-container.mat-dialog-container {
    border-radius: 0px;
    padding: 40px 40px 30px;

    button.mat-button {
      border-radius: 0px;
    }
  }

  mat-dialog-actions {
    justify-content: flex-end;
    // Subtract 10px to align button text with content
    margin-right: -10px;

    .mat-button {
      padding-left: 10px;
      padding-right: 10px;
      min-width: 50px;
    }
  }

  mat-dialog-actions .mat-button {
    &:last-child:not([disabled]) {
      color: mat.get-color-from-palette($primary);
    }

    &:nth-last-child(2) {
      font-weight: 400;
    }
  }
}
