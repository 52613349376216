@use '@angular/material' as mat;
@mixin outline($offset: 0) {
  outline: 3px solid mat.get-color-from-palette($apd-orange, 500);
  outline-offset: $offset;
}

@mixin apd-default-keyboard-focus() {
  .cdk-focused.cdk-keyboard-focused {
    @include outline(-3px);
  }

  .cdk-focused.cdk-mouse-focused,
  .cdk-focused.cdk-program-focused {
    outline: none;
  }
}
