@use '@angular/material' as mat;
@import '../theme';

@import 'styles/component-styles/index';
@import '~ngx-toastr/toastr.css';

@include loadTheme();

button {
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
  }
}

.cdk-focused.cdk-mouse-focused,
.cdk-focused.cdk-program-focused {
  outline: none;
}

button.round {
  border-radius: 20px;
  font-size: 14px;
  height: auto;
  padding: 4px 12px;
}

.bold {
  font-weight: bold;
}

// Use in dropdowns to indicate why an option is not selectable
.dropdown-option {
  display: flex;
  flex-direction: column;

  .dropdown-option__name {
    line-height: 2;
  }

  .dropdown-option__note {
    color: var(--warning-color);
    font-size: 75%; /* Same as used for field error messages in the theme */
    flex: 1 1 auto;
    line-height: 1.25;
  }
}

.sr-only {
  opacity: 0;
  pointer-events: none;
  position: fixed;
}

.white-spinner circle {
  stroke: white;
}

:root {
  // Colors 💅
  --primary-color: #{mat.get-color-from-palette($apd-primary-palette)};
  --success-color: #{mat.get-color-from-palette($apd-green, 900)};
  --success-color-text: #fff;
  --error-color: #{mat.get-color-from-palette($apd-red, 900)};
  --warning-color: #{mat.get-color-from-palette($apd-orange, 900)};
  --warning-color-text: #fff;
  --disabled-color: #{mat.get-color-from-palette($apd-grey, 300)};
  --text-color: #333;
  --background-color: #{map-get($apd-light-theme-background, background)};
  --banner-background-color: #c9d5da;
  --banner-strong-background-color: #5b7883;
  --banner-strong-color: #fff;

  // Ix3 colors - new design system
  --ix3-color-emerald: #0aab7d;
}
