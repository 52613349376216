.handover {
  align-items: baseline;
  font-weight: bold;
  padding: 0.0625rem 1rem;
  text-transform: uppercase;
}

.handover--part {
  background-color: var(--warning-color);
  color: var(--warning-color-text);
}

.handover--full {
  background-color: var(--success-color);
  color: var(--success-color-text);
}
