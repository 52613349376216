@mixin mat-button-toggle-style() {
  mat-button-toggle.mat-button-toggle-checked {
    background-color: $apd-accent-color;

    .mat-button-toggle-focus-overlay {
      border-bottom: 0;
    }
  }

  .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
    color: white;
  }

  .mat-button-toggle.cdk-focused.cdk-keyboard-focused {
    @include outline(-3px);

    .mat-button-toggle-focus-overlay {
      opacity: 0 !important;
    }
  }
}
