@use '@angular/material' as mat;
@mixin mat-tooltip-style() {
  div.mat-tooltip {
    font-size: .75rem;
    line-height: .875rem;
    background-color: mat.get-color-from-palette($apd-dark-blue, 900);
    margin: 8px;
    border-radius: 0;
  }
}
