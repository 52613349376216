@use '@angular/material' as mat;
@import '../theme';


$enabledCheckBoxFrameColor: mat.get-color-from-palette($apd-white, 100);
$disabledCheckBoxFrameColor: mat.get-color-from-palette($apd-grey, 600);

table[mat-table] {
  --cell-gutter: 6px;
  --row-height: 40px;
  --row-padding: 4px;
  --table-left-gutter: 24px;
  --table-right-gutter: 24px;
  --table-col-width-icon: 100px;

  width: 100%;

  th {
    color: white;
    font-weight: bold;
    font-size: 14px;
    text-transform: uppercase;
    white-space: nowrap;
  }

  th > mat-icon {
    display: block;
  }

  th:not(:empty),
  td:not(:empty) {
    height: var(--row-height);
    padding-bottom: var(--row-padding);
    padding-top: var(--row-padding);
    transition: all 0.225s ease;
    transition-property: height, padding;
  }

  th:first-child,
  td:first-child {
    padding-left: var(--table-left-gutter);
  }

  th:last-child,
  td:last-child {
    padding-right: var(--table-right-gutter);
  }

  th:not(:first-child),
  td:not(:first-child) {
    padding-left: var(--cell-gutter);
  }

  th:not(:last-child),
  td:not(:last-child) {
    padding-right: var(--cell-gutter);
  }

  th .mat-checkbox-disabled .mat-checkbox-label {
    color: white;
  }

  td.table-cell-collapsed {
    height: 0;
    padding-top: 0;
    padding-bottom: 0;
  }

  tr[mat-header-row] {
    background-color: var(--primary-color);
    height: auto;

    .mat-checkbox-frame {
      border-color: $enabledCheckBoxFrameColor;
    }

    .mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
      border-color: $disabledCheckBoxFrameColor;
    }
  }

  tr.mat-row {
    height: auto;
  }
}
