@use '@angular/material' as mat;
@mixin typography() {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: map_get($apd-dark-blue, 900);
  }
}

$apd-typography: mat.define-typography-config(
  $font-family: 'Arial, sans-serif',
  $headline: mat.define-typography-level(20px, 24px, 600),     // h1
  $title: mat.define-typography-level(18px, 22px, 600),        // h2
  $subheading-2: mat.define-typography-level(14px, 17px, 600), // h3
  $button: mat.define-typography-level(14px, 14px, 600)
);
