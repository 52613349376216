@mixin mat-checkbox-style() {
  mat-checkbox.mat-checkbox {
    .mat-checkbox-frame,
    .mat-checkbox-background {
      border-radius: 0;
      width: 14px;
      height: 14px;
    }

    &.cdk-focused.cdk-keyboard-focused {
      @include outline(6px);

      .mat-checkbox-persistent-ripple {
        opacity: 0;
      }
    }
  }
}
