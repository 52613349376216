@use '@angular/material' as mat;
@mixin mat-progress-bar-style() {
  mat-progress-bar .mat-progress-bar-fill::after {
    background-color: mat.get-color-from-palette($apd-orange, 50);
  }

  mat-progress-bar .mat-progress-bar-element {
    background-color: mat.get-color-from-palette($apd-orange, 500);
  }
}
