@use '@angular/material' as mat;
@mixin mat-table-style($theme) {
  $primary: map-get($theme, primary);
  $primary-color: mat.get-color-from-palette($primary);

  mat-table {
    background-color: transparent;

    .mat-row.cdk-focused.cdk-keyboard-focused {
      @include outline(-4px);
    }

    .mat-header-row,
    .mat-row {
      min-height: 40px;
    }

    .number {
      text-align: right;
      padding-left: 5px;
      padding-right: 5px;
    }

    .text {
      text-align: left;
      padding-left: 5px;
      padding-right: 5px;
    }

    mat-row.sum,
    mat-cell.sum {
      font-weight: bold;
      background-color: mat.get-color-from-palette($apd-grey, 200);

      mat-cell {
        font-weight: bold;
        background-color: mat.get-color-from-palette($apd-grey, 200);
      }
    }

    mat-header-row.mat-header-row {
      background-color: map-get($apd-light-theme-background, background);

      .mat-header-cell {
        font-weight: 700;
        font-size: 14px;
      }
    }

    mat-row {
      background-color: white;

      &.active {
        background-color: map-get($apd-grey, 300);
      }

      &.selected {
        background-color: map-get($apd-grey, 300);
      }
    }
  }

  mat-table[color='primary'] {
    .mat-header-row,
    .mat-header-cell {
      background-color: $primary-color;
      color: white;
    }
  }

  mat-table.hover-rows {
    .mat-row:hover {
      background-color: mat.get-color-from-palette($apd-grey, 100);
      cursor: pointer;
    }
  }
}
