@mixin mat-selection-list-style() {
  mat-selection-list.mat-selection-list {
    &.cdk-focused.cdk-keyboard-focused {
      @include outline(-3px);
    }

    .mat-list-option {
      &.cdk-focused.cdk-keyboard-focused {
        @include outline(-4px);
        background-color: transparent;
      }

      &.cdk-mouse-focused:not(:hover) {
        background: transparent;
      }
    }
  }
}
